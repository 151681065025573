<template>
    <uw-content title="数据字典" padding="10px">

        <!-- 分类 -->
        <template slot="prefix">
            <el-button size="mini" :type="search.model == null ? 'primary' : 'text'" @click="search.model = null; TableSearch()">全部</el-button>
            <el-button size="mini" :type="search.model == 'wo' ? 'primary' : 'text'" @click="search.model = 'wo'; TableSearch()">工单</el-button>
        </template>

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.dialogCreate.Open()">新增字段</el-button>
        </template>

        <!-- 表格 -->
        <uw-table>
            
            <!-- 检索 -->
            <template slot="search">

                <el-input @input="TableSearch" v-model="search.name"  clearable  size="mini" placeholder="字段名称"></el-input>

                <data-dict-select-type v-model="search.type" @onChange="TableSearch" />
            </template>

            <!-- 数据 -->
            <template slot="data">
                <vxe-table ref="xTable" :data="table" :loading="loading" @sort-change="TableSort" :export-config="{}" :print-config="{}" height="100%">
                    
                    <!-- ID -->
                    <vxe-column width="60" field="id" title="ID" sortable></vxe-column>

                    <!-- 业务模块 -->
                    <vxe-column width="120" field="model" title="业务模块" sortable>
                        <template #default="{ row }">
                            <span v-if="row.model == 'wo'">工单</span>
                            <span v-else>{{ row.model }}</span>
                        </template>
                    </vxe-column>

                    <!-- 字段名称 -->
                    <vxe-column width="120" field="name" title="字段名称" sortable></vxe-column>

                    <!-- 类型 -->
                    <vxe-column width="120" field="type" title="类型" sortable>
                        <template #default="{ row }">
                            <span v-if="row.type == 'text'">单行文本</span>
                            <span v-else-if="row.type == 'textarea'">多行文本</span>
                            <span v-else-if="row.type == 'select'">下拉菜单(单选)</span>
                            <span v-else-if="row.type == 'select-multiple'">下拉菜单(多选)</span>
                            <span v-else-if="row.type == 'radio'">单选框</span>
                            <span v-else-if="row.type == 'checkbox'">多选框</span>
                            <span v-else-if="row.type == 'int'">整数</span>
                            <span v-else-if="row.type == 'float'">浮点数</span>
                            <span v-else-if="row.type == 'date'">日期</span>
                            <span v-else-if="row.type == 'date-range'">日期范围</span>
                            <span v-else-if="row.type == 'time'">时间</span>
                            <span v-else-if="row.type == 'time-range'">时间范围</span>
                            <span v-else-if="row.type == 'datetime'">日期时间</span>
                            <span v-else-if="row.type == 'datetime-range'">日期时间范围</span>
                            <span v-else>{{ row.type }}</span>
                        </template>
                    </vxe-column>

                    <!-- 是否必填 -->
                    <vxe-column width="100" field="required" title="是否必填" sortable>
                        <template #default="{ row }">
                            <el-link :underline="false" v-if="row.required" type="danger">必填</el-link>
                            <el-link :underline="false" v-if="!row.required" type="info">非必填</el-link>
                        </template>
                    </vxe-column>

                    <!-- 提示词 -->
                    <vxe-column width="140" field="placeholder" title="提示词">
                        <template #default="{ row }">
                            <el-link v-if="row.placeholder" :underline="false" type="info">{{ row.placeholder }}</el-link>
                            <el-link v-if="!row.placeholder" :underline="false" type="info">!未配置</el-link>
                        </template>
                    </vxe-column>

                    <!-- 默认值/选项 -->
                    <vxe-column min-width="140" field="form" title="默认值/选项"></vxe-column>

                    <!-- 操作 -->
                    <vxe-column width="80" title="操作" align="center" fixed="right">
                        <template #default="{ row }">
                            <el-link type="primary" class="w-margin-r-5" @click="$refs.dialogUpdate.Open(row.id)">编辑</el-link>
                            <el-link type="danger" @click="TableDelete(row.id)">删除</el-link>
                        </template>
                    </vxe-column>
                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>

        </uw-table>

        <data-dict-create ref="dialogCreate" @onChange="TableSearch" />
        <data-dict-update ref="dialogUpdate" @onChange="TableSearch" />
    </uw-content>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            
            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                model: null,
                name: null,
                type: null,
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },
    mounted() {
        // 初始化数据
        this.TableSearch()
    },
    methods: {
        
        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.loading = true
            this.$http.post(
                '/9api/data-dict/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order
                }
            ).then((rps) => {
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 表格数据 - 移除
        |--------------------------------------------------
        |
        */

        TableDelete(id) {
            this.$confirm('删除后无法恢复,可能对已有工单造成影响，请谨慎操作', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true,
                type: 'error'
            }).then(() => {
                this.$http.post('/9api/data-dict/delete', { id: id }).then((rps) => {
                    this.TableSearch()
                })
            })
        },

        /**
        |--------------------------------------------------
        | 数据排序
        |--------------------------------------------------
        |
        */

        TableSort(column) {
            this.order.method = column.order ? column.order : this.order.method
            this.order.column = column.field
            this.TableSearch()
        },
    }
}
</script>